import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import icalendar_url_1 from '../../../../../../../common/src/assets/image/icalendar_url_1.png';
import icalendar_url_2 from '../../../../../../../common/src/assets/image/icalendar_url_2.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        from any iCalendar (ics) URL. Here's how:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar </strong>button on the{' '}
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>{' '}
          step and select the <strong>Online -&gt; iCalendar (URL)</strong>{' '}
          option. The <strong>Add Calendar Source - iCalendar URL</strong>{' '}
          window will appear.
          <br />
          <Image alt="" src={icalendar_url_1} style={{ width: 690 }} />
        </li>
        <li>
          Type or paste the desired URL into the text box. The OK button will be
          enabled after a valid URL has been entered.
          <br />
          <Image alt="" src={icalendar_url_2} style={{ width: 690 }} />
        </li>
        <li>
          If the calendar is hosted on a secure server that requires
          authentication, check the{' '}
          <strong>Server requires authentication </strong>box and enter your
          username and password.
        </li>
        <li>
          When finished, click the <strong>OK </strong>button.
        </li>
        <li>
          PrintableCal will attempt to find the calendar name in the iCalendar
          feed. If the calendar name isn't present, a window will appear asking
          for a calendar name. Click the <strong>OK </strong>button after
          entering the calendar name. Make sure to enter a name that isn't
          already used by one of the other calendar data sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/iCalendar-URL"
      commentsId="commentsplus_post_140_489"
      title="Print Calendar from an iCalendar URL"
      description="PrintableCal can create printable calendars from any iCalendar (ics) URL."
      keywords="print icalendar, print ical, print ics, import icalendar, import ics, printable calendar, printable template, Excel calendar, Word calendar, print schedule"
      content={content}
    />
  );
};

export default Documentation;
